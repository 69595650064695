import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'it_support',
  formId: 'UK%20IT%20Support',
  title: 'IT support',
  subTitle: 'Fuld fleksibilitet på IT support',
  icon: '/icons/it_support.svg',
  image: '/assets/images/sunrise/it_support_supermercat.png',
  theme: 'sunrise-it_support',
  sections: [
    {
      sectionId: 'hero',
      label: 'IT support',
      title: 'Fuld fleksibilitet på IT support',
      description:
        'Har I også en medarbejder ‘med flair for IT,’ som bruger alt for meget af sin tid på at genstarte jeres computere, når de driller? Kunne det være dejligt at have en at ringe til i stedet? En, der både svarer og kan løse problemet?',
      image: '/assets/images/sunrise/it_support_supermercat.png',
      icon: '/icons/it-support.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Vi kan det hele!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/it_support/service_1.svg',
          title: 'Backup of mail systemer',
          description: '',
        },
        {
          icon: '/assets/images/services/it_support/service_2.svg',
          title: 'IT service',
          description: '',
        },
        {
          icon: '/assets/images/services/it_support/service_3.svg',
          title: 'WiFi',
          description: '',
        },
        {
          icon: '/assets/images/services/it_support/service_4.svg',
          title: 'IT hardware',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Nørderne er på vej!',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/it_support/journey_1.svg',
          title: 'Fortæl os hvad der driller',
          description: 'Så kan vi bedst hitte ud af hvilke nørder vi skal spørge.',
        },
        {
          icon: '/assets/images/services/it_support/journey_2.svg',
          title: 'Hurtigt møde',
          description: 'Vi kobler jer sammen med vores IT partner, så de kan finde den bedste løsning til jer.',
        },
        {
          icon: '/assets/images/services/it_support/journey_3.svg',
          title: 'Hurtigt møde',
          description: 'Overblik. Sort på hvidt. DU skal bare sige ja tak.',
        },
        {
          icon: '/assets/images/services/it_support/journey_4.svg',
          title: 'Så let er det!',
          description: 'Køreklar uden, at I har smøget ærmerne op. Nørderne kommer... ',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker vore kunder IT support fra Good Monday?',
      description: '',
      image: '/assets/images/services/it_support/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-umbrella-outline',
          title: 'Dit tech-team skal fredes',
          description:
            'Mange steder lander IT bruger-problemer i udviklingsafdelingen. Det er der ikke meget udvikling i.',
        },
        {
          icon: 'eva eva-pie-chart-outline',
          title: 'Slip for fastansættelser',
          description: 'Få fuld flexibilitet uden at skulle bikse med årsnormer.',
        },
        {
          icon: 'eva eva-power-outline',
          title: 'Køreklar',
          description: 'Vi har en hær af topprofessionelle IT folk og lover resultater med det samme.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at du via Good Monday kan få dit eget in-house IT support team?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
